import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { detectIE } from './libraries/ApplicationService';
import { initializeSentry } from './services/sentry';
import './i18n';

initializeSentry();

// REGISTER ERROR OVERLAY
const showErrorOverlay = err => {
  // must be within function call because that's when the element is defined for sure.
  const ErrorOverlay = customElements.get('vite-error-overlay');
  // don't open outside vite environment
  if (!ErrorOverlay) {
    return;
  }
  console.error(err);
  const overlay = new ErrorOverlay(err);
  document.body.appendChild(overlay);
};

const startApp = () => {
  if (import.meta.env.MODE === 'development') {
    window.addEventListener('error', showErrorOverlay);
    window.addEventListener('unhandledrejection', ({ reason }) =>
      showErrorOverlay(reason)
    );
  }
  ReactDOM.render(<App />, document.getElementById('root'));
};

const cordovaReady = () => {
  startApp();
};

if (window.cordova) {
  document.addEventListener('deviceready', cordovaReady, false);
  serviceWorker.unregister();
} else {
  if (!navigator.cookieEnabled) {
    window.location.href = '/cookies.html';
  }
  detectIE();
  startApp();
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  serviceWorker.register();
}
