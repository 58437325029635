export const GOGETTER_SELECTION_ANYONE = 'anyone';
export const GOGETTER_SELECTION_FAVOURITES = 'favourites';
export const GOGETTER_SELECTION_TEAMS = 'teams';
export const GOGETTER_SELECTION_WORKFORCE = 'workers';

export const CURRENT_POOL_ALL_WORKFORCE = 'CURRENT_POOL_ALL_WORKFORCE';
export const CURRENT_POOL_SINGLE_WORKFORCE = 'CURRENT_POOL_SINGLE_WORKFORCE';
export const CURRENT_POOL_ALL_TEAM = 'CURRENT_POOL_ALL_TEAM';
export const CURRENT_POOL_SINGLE_TEAM = 'CURRENT_POOL_SINGLE_TEAM';
export const CURRENT_POOL_ALL_FAVOURITES = 'CURRENT_POOL_ALL_FAVOURITES';
export const CURRENT_POOL_SINGLE_FAVOURITE = 'CURRENT_POOL_SINGLE_FAVOURITE';
export const CURRENT_POOL_ANY = 'CURRENT_POOL_ANY';

export const FAILED = 'failed';

export const SUGGESTED_MAX_HOURS_FOR_BREAK = 5;
